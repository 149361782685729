/* eslint-disable global-require */
import InnerHTML from 'dangerously-set-html-content'
import React from "react";
import Image from "./image";
import featuresData from "../../content/features.json";
import Button from "./button";

const link = featuresData.link.toLowerCase();
const baseDelay = 100;
const icons = [];
featuresData.icons.map((feature, index) => {
  icons.push(
    <div key={feature.icon_text} className="w-full sm:w-1/2 md:w-1/3 p-5">
      <div
        className="p-10 max-w-2xs m-auto"
        data-sal="zoom-in"
        data-sal-delay={(index + 1) * baseDelay}
      >
        <Image
          filename={feature.icon}
          alt={feature.icon_text}
        />
      </div>
      <p className="text-2xl text-center">{feature.icon_text}</p>
    </div>
  );
});

const Features = () => {
  return (
    <div>
      <div className="container m-auto p-8 sm:pt-16 flex flex-wrap md:max-w-4xl justify-center">
        <div>
          <h2 className="text-3xl xl:text-4xl pb-6 text-center gibson-bold">
            {featuresData.headline}
          </h2>
          <div className="text-base lg:text-xl">
            <InnerHTML html={featuresData.copy}/>
          </div>
        </div>
        <div className="md:mb-6 mb-0 w-full flex md:flex-nowrap flex-wrap justify-center">
          {icons}
        </div>
        <Button label={featuresData.label} link={link} />
      </div>
    </div>
  );
};

export default Features;
