import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import Features from "../components/features";
import Pricing from "../components/pricing";
import Tiers from "../components/tiers";

const IndexPage = () => (
  <Layout hideHeaderAngle={true}>
    <SEO title="Home" />
    <Hero />
    <Features />
    <Pricing />
    <Tiers />
  </Layout>
);

export default IndexPage;
