/* eslint-disable global-require */
import React from "react";
import Carousel from "./carousel";
import heroData from "../../content/hero.json";

import { formatTextarea } from "../utils";

const Hero = () => {
  return (
    <div className="angle-dramatic-bl-bg bg-contain bg-bottom bg-no-repeat bg-charcoal">
      <div className="container m-auto p-8 sm:pt-16 flex flex-wrap md:flex-nowrap">
        <div className="text-white w-full md:w-1/2 md:pr-16 pb-10 md:pb-0">
          <h1 className="text-5xl lg:text-8xl text-center md:text-left py-0 md:py-6 tracking-wide gibson-bold leading-tight">
            {formatTextarea(heroData.headline)}
          </h1>
        </div>
        <Carousel images={heroData.images} />
      </div>
    </div>
  );
};

export default Hero;
