/* eslint-disable global-require */
import InnerHTML from 'dangerously-set-html-content'
import React from "react";
import Button from "./button";
import tiersData from "../../content/tiers.json";

const tiers = [];
const numberOfBranches = 3;

tiersData.tiers.map((tier, index) => {
  const branchNumber = index % numberOfBranches;
  tiers.push(
    <React.Fragment key={tier.name}>
      <p className="text-3xl text-center sm:row-span-2 sm:border-r-10 border-charcoal uppercase tracking-wide sm:pt-8 tier gibson-bold">
        <span
          className={`border-b-10 pb-3 border-charcoal block w-full branch branch--tiers-${branchNumber} relative`}
          data-sal="slide-right"
        >
          {tier.name}
        </span>
      </p>
      <p className="text-2xl pt-16 sm:pt-8 sm:pl-10 sm:p-3">{tier.headline}</p>
      <div className="text-lg sm:text-xl pb-24 pt-6 sm:pl-10 sm:pr-3 text-gray-600">
        <InnerHTML html={tier.description}/>
      </div>
    </React.Fragment>
  );
});

const Tiers = () => {
  const link = tiersData.link.toLowerCase();
  return (
    <div>
      <div className="container m-auto p-8 sm:pt-16 flex flex-wrap md:max-w-4xl justify-center">
        <div className="mt-16 sm:my-16 w-full flex md:flex-nowrap flex-wrap tiers-grid">
          {tiers}
        </div>
        <div className="w-full text-center text-3xl uppercase tracking-wide p-5 gibson-bold">
          {tiersData.cta}
        </div>
        <Button label={tiersData.label} link={link} />
      </div>
    </div>
  );
};

export default Tiers;
