/* eslint-disable global-require */
import React, { useState } from "react";
import pricingData from "../../content/pricing.json";
import Button from "./button";
import Image from './image';

const tiers = [];
pricingData.tiers.map((tier) => {
  tiers.push(
    <div
      key={tier.headline}
      className="w-full sm:w-1/2 md:w-1/3 p-8 md:p-10 text-center"
    >
      <p className="text-2xl xl:text-3xl pb-3">{tier.headline}</p>
      <p className="text-base sm:text-xl">{tier.details}</p>
    </div>
  );
});

const getProgressBarWidth = (currentValue) => {
  const makePercentage = 100;
  let progressWidth;
  const barLength = tiers.length;
  if (parseInt(currentValue, 10) === barLength) {
    progressWidth = 1;
  } else {
    progressWidth = (currentValue - 1) / (barLength - 1);
  }
  return `${progressWidth * makePercentage}%`;
};

const Pricing = () => {
  const link = pricingData.link.toLowerCase();
  const [sliderValue, setSliderValue] = useState(1);

  return (
    <div className="angle-midpage-left-bg bg-charcoal text-white bg-contain">
      <div className="container m-auto px-8 py-32 sm:py-48 flex flex-wrap md:max-w-4xl justify-center">
        <div className="w-full">
          <h2 className="text-3xl xl:text-4xl pb-4 text-center border-b-10 tracking-wide branch branch--pricing relative border-white gibson-bold">
            {pricingData.headline}
          </h2>
          <div className="w-full flex flex-wrap justify-center py-12">
            <div className="w-full md:p-10 flex flex-col sm:flex-row justify-center items-center">
              <h3 className="text-12xl text-center gibson-bold px-3 tracking-widest">
                $0
              </h3>
              <p className="text-5xl gibson-bold max-w-xs leading-tight px-3 text-center sm:text-left">
                {pricingData.free_copy}
              </p>
            </div>
            <div className="w-full p-8 bg-lightgrey text-charcoal border-charcoal border-double border-16 mt-6 rounded-3xl">
              <h3 className="text-center text-3xl md:text-4xl gibson-bold py-6">
                {pricingData.tiers[sliderValue - 1].headline}
              </h3>
              <div className="relative w-full md:w-1/2 m-auto">
                <div
                  className="slider-progress"
                  style={{
                    width: getProgressBarWidth(sliderValue),
                  }}
                />
                <div className="slider-background" />
                <input
                  type="range"
                  id="pricing"
                  name="pricing"
                  min="1"
                  max={tiers.length}
                  defaultValue={sliderValue}
                  onChange={(e) => setSliderValue(e.target.value)}
                />
              </div>
              <p className="text-center text-2xl py-6">
                {pricingData.tiers[sliderValue - 1].details}
              </p>
            </div>
          </div>
        </div>
        <div className="pb-12 flex items-center">
          <div data-sal="zoom-in" className="max-w-2xs p-6 sm:block">
              <Image
                alt="Shopping cart icon"
                filename={pricingData.image}
              />
          </div>
          <div className="py-0 sm:p-8">
            <h3 className="text-2xl xl:text-3xl">{pricingData.cart}</h3>
            <p className="text-base sm:text-lg py-6">{pricingData.cart_copy}</p>
          </div>
        </div>
        <Button label={pricingData.label} link={link} />
      </div>
    </div>
  );
};

export default Pricing;
